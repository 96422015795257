.contact_container{
    background-color: #000;
    color:#ffff;
    margin:0;
    padding:40px 60px 0;
}
.title{
    font-family: "Alegreya Sans SC", sans-serif;
    font-size: 26px;
    font-weight: 500;
    display: flex;
    align-items: baseline;
    color:#4f4f4f;
}
.title div span{
    font-size: 36px;
}
.contact_content{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
.contact_content_left{
    width:50%;
    font-size: 24px;
    font-family: "Aoboshi One", sans-serif;
    text-align: start;
    margin-top: 20px;
}
.contact_form{
    width:100%;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}
.form_container{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.form_style{
    width:40%;
    padding: 10px;
    outline: none;
    border: none;
    border-radius: 10px;
    margin: 10px 0;
    background-color: #3D3D3D;
    color:#747474;
}

.send_button {
    width: 20%;
    padding: 10px;
    border-radius: 20px;
    background-color: #20CAD9; /* Fallback color */
    background-image: linear-gradient(to right, #20CAD9, #002f39); /* Gradient background */
    background-size: 200% 100%; 
    background-position: left bottom; /* Start position */
    color: #fff;
    font-size: 16px;
    font-family: sans-serif;
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
    /* transition: transform 0.3s ease, box-shadow 0.3s ease; */
    transition: background-position 0.5s ease, transform 0.5s ease; 
}

.send_button:hover {
    background-position: right bottom; 
    transform: scale(1.1);
    /* box-shadow: 0 0px 20px rgb(255 255 255 / 60%); */
}

.footer{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin:8px 0 24px;
    color:#808080;
}

.copyright{
    color:#747474;
}
.contact_details{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.mail{
    font-size: 30px;
}

.mail a{
    color:#808080;
}

.uderline{
    width:100%;
    height:2px;
    background-color: #808080;
    margin:14px 0;
}
.contact_no{
    font-size: 20px;
    
}

.contact_no a {
    color:#808080;
}

.socialmedia{
    display: flex;
    margin:20px 0 0;
}

.socialmedia div{
    padding: 10px;
}

.socialmedia div a img{
    transition:transform 0.3s ease;
}

.socialmedia div a img:hover{
    transform: scale(1.2);
    box-shadow: 0px 0px 20px -2px #fff;
    border-radius: 50%;
}

.error{
    font-size: 14px;
    font-family: sans-serif;
    font-weight: lighter;
    color: rgb(201, 106, 106);
}

@media screen and (max-width: 767px) {

    .contact_container {
        padding: 40px 30px 0 30px;
    }

    .contact_content, .footer  {
        flex-direction: column;
    }

    .contact_content_left, .contact_content_right img  {
        width: 100%;
    }

    .footer {
        align-items: center;
    }

    .contact_content_right {
        padding-top: 30px;
    }
    .contact_details {
        justify-content: center;
        align-items: center;
    }

    .contact_form input{
        width: 94%;
    }

    .send_button {
        width: 80%;
        margin: 20px auto 0;
    }

    .mail {
        font-size: 25px;
    }

    .copyright {
        padding-bottom: 20px;
    }
}