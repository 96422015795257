.service_container{
    background-color: #000;
    color:#ffff;
    margin:0;
    padding:40px 60px 0;
}
.title{
    font-family: "Alegreya Sans SC", sans-serif;
    font-size: 26px;
    font-weight: 500;
    display: flex;
    align-items: baseline;
    color:#4f4f4f;
}
.title div span{
    font-size: 36px;
}
.service_content{
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
}
.service_content_left{
    width:50%;
    font-size: 24px;
    font-family: "Aoboshi One", sans-serif;
    text-align: start;
    margin-top: 20px;
}
.service_listing{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-family: "Aoboshi One", sans-serif;
    height: 80vh;
    margin-top: 20px;
}
.list_row_1, .list_row_2, .list_row_3{
    display: flex;
    justify-content: center;
    width:100%;

}
.list_row_2{
    margin-top: 20px;
}
.row_items{
    width:25%;
}

.img_hover {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 50%;
  }
  
  .img_hover:hover {
    transform: scale(1.1);
    box-shadow: 0 0px 20px rgb(255 255 255 / 60%);
    border-radius: 50%;
  }

  @media screen and (max-width: 767px) {

    .service_container{
        padding: 40px 30px 0 30px;
    }

    .service_content {
        flex-direction: column;
    }

    .service_content_right {
        padding-top: 30px;
    }
    .service_content_right img, .service_content_left {
        width: 100%;
    }

    .service_listing {
        font-size: 18px;
        justify-content: flex-start;
        height: 80vh;
    }

    .service_listing > div {
        flex-wrap: wrap;
    }

    .row_items {
        width: 50%;
    }
    .row_items > div:last-child {
        margin-bottom: 35px;
    }
  }