
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Averia+Serif+Libre&display=swap');


.home_container{
    width:100%;
    height:100vh;
    background-image: url(../../assets/images/home-page-bg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
}
.home_content{
    display: flex;
    height:80%;
    position:relative;
    align-items:center ;
}
.home_description{
    width:56%;
}
.main_desc{
    margin: 0;
    padding: 0;
    font-size: 64px;
    color: #fff;
    font-family: "Archivo Black", sans-serif;
}
.home_sub_desc{
    font-size: 32px;
    color:rgba(255, 255, 255, 60%);
    padding-left: 20px;
    font-family: "Averia Serif Libre", sans-serif;
    display: flex;
    align-items: flex-start;
}
.home_sub_desc span{
    position: absolute;
    left:150px;
}
.home_sub_left{
    width: 21%;
    display: flex;
    justify-content: center;
    padding-top: 20px;
}
.home_sub_right{
    width: 70%;
}
.menu_bar{
    width: 387px;
    height: 287px;
    background-color: #363636;
    position: absolute;
    right: 0;
    opacity: 0.5;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 26px 0 26px 68px;
    box-sizing: border-box;
}
.menu_items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    cursor: pointer;
}
.about_text, .service_text, .contact_text{
    color:#fff;
    z-index: 1;
}
.contact_desc, .about_desc, .service_desc{
    font-size: 12px;
    margin-top: 2px;
    color:#fff;
}

.menu_texts span{
    position: absolute;
}
.about_text span{
    left:52px;
    top:31px;
}

.service_text span{
    left:52px;
    top:131px;
}

.contact_text span{
    left:52px;
    bottom:48px;
}

.about_text:hover, .service_text:hover, .contact_text:hover{
    color:#A4D907;
}

@media screen and (max-width: 767px) {

    .home_container{
        height: auto;
        background-size: cover;
    }

    .main_desc {
        font-size: 55px;
    }

    .home_sub_left{
        padding-top: 30px;
    }
    
    .home_sub_right {
        padding-top: 45px;
    }

    .home_description {
        width: 100%;
    }
    .menu_bar {
        display: none;
    }
}