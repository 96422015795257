.header_container{
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding: 10px 20px;
    position: sticky;
    top:0;
    z-index: 1000;
    background-color: #000;
}

.logo{
    width:20%;
    cursor: pointer;
}

.logo img{
    width:100%;
}

.menu_container{
    display: flex;
    width: 10%;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
}
.menu_text{
    color:#ffff;
    font-size: 16px;
    font-family:sans-serif;
    line-height: 1;
}
.menu_text:hover{
    color:#A4D907;
}
.menu_icon{
    width:30px;
    height:20px;
}
.menu_icon img{
    width:100%;
}

.menu_icon, .mobile_menu_bar_hide, .close_hide {
    display: none;
}

.mobile_menu_bar_hide   {
    display: initial;
}

.mobile_menu_bar {
    position: fixed;
    top: -100%;
    left: 0%;
    width: 100%;
    background: #000;
    transition: top 0.5s;
}

@media screen and (max-width: 767px) {
    .logo{
        width:50%;
    }

    .menu_icon {
        display: initial;
    }
    .mobile_menu_bar_show {
        top: 0;
    }

    .close_show {
        display: inline-block;
        position: absolute;
        right: 30px;
        top: 20px;
        font-size: 24px;
        color: #fff;
    }

    .menu_items {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        padding-bottom: 50px;
    }

    .menu_items:first-child {
        padding-top: 70px;
    }
    .menu_items > div:last-child {
        font-size: 15px;
    }

    .about_text, .service_text, .contact_text{
        color:#fff;
        z-index: 1;
    }
    .contact_desc, .about_desc, .service_desc{
        font-size: 12px;
        margin-top: 2px;
        color:#fff;
    }

    .menu_texts {
        font-size: 25px;
    }
    
    .menu_texts span{
        position: absolute;
    }
}